// import React, { useState, useEffect } from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, IconButton, Box, Modal, TextField } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { Typography } from 'antd';
// import Navbar from '../../components/Navbar/Navbar';
// import Footer from '../../components/Footer/Footer';
// import { Redo } from '@mui/icons-material';
// import paymentIcon from "../../assests/icons/payments.png";
// import { useNavigate } from 'react-router-dom';
// import easypaisa from "../../assests/icons/easy-paisa.png";
// const CartPage = () => {
//   const navigate = useNavigate();
//   const [cartItems, setCartItems] = useState([]);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [updatedQuantity, setUpdatedQuantity] = useState(1);
//   const [updatedColor, setUpdatedColor] = useState('');
//   const token = useSelector((state) => state.auth.token);

//   // Fetch cart items from API
//   const fetchCartItems = async () => {
//     try {
//       const response = await axios.get('https://www.arshymuala.com/api/cart/get', 
//         {
//             headers: {
//               Authorization: `Bearer ${token}`, // Add token in headers
//             },
//         }
//       );
//       setCartItems(response.data.data.lineItems);
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         setCartItems([]); // Set empty array if 404 is received
//       } else {
//         console.error('Error fetching cart items:', error);
//       }
//     }
//   };

//   useEffect(() => {
//     fetchCartItems();
//   }, []);

//   // Handle removing an item
//   const handleRemove = async (id, color) => {
//     try {
//       await axios.delete('https://www.arshymuala.com/api/cart/delete', {
//         data: { productId: id, color }, // Include data as part of the request body
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token in headers
//         },
//       });
      
//       // Fetch updated cart items after removing the item
//       fetchCartItems();
//     } catch (error) {
//       console.error('Error removing item:', error);
//     }
//   };
  
//   // Handle opening modal for editing item
//   const handleEdit = (item) => {
//     setSelectedItem(item);
//     setUpdatedQuantity(item.quantity);
//     setUpdatedColor(item.color);
//     setModalOpen(true);
//   };

//   // Handle updating the item
//   const handleUpdate = async () => {
//     if (!selectedItem) return;

//     try {
//       await axios.put('https://www.arshymuala.com/api/cart/update', {
//         productId: selectedItem._id,
//         quantity: updatedQuantity,
//         color: updatedColor,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token in headers
//         },
//       });
//       fetchCartItems(); // Refresh items
//       setModalOpen(false);
//     } catch (error) {
//       console.error('Error updating item:', error);
//     }
//   };

//   // Handle Stripe session API call
//   const handleContinueShopping = async () => {
//     const payload = {
//       line_items: cartItems.map(item => ({
//         price_data: {
//           currency: 'usd',
//           product_data: {
//             name: item.productName,
//             metadata: {
//               color: item.color,
//               size: item.size,
//             },
//           },
//           unit_amount: item.unitAmount,
//         },
//         quantity: item.quantity,
//       })),
//     };

//     try {
//       const response = await axios.post('https://www.arshymuala.com/api/strip/sessions', payload, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Add token in headers
//         },
//       });
//       const { stripeUrl } = response.data;

//       // Redirect to Stripe checkout
//       if (stripeUrl) {
//         window.location.href = stripeUrl;
//       }
//     } catch (error) {
//       console.error('Error creating Stripe session:', error);
//     }
//   };


//   return (
//     <React.Fragment>
//     <Navbar/>
//     <Box py={4} px={{xs: 1, md:10}}>

//       <Box sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: 2}}>
//       <Button endIcon={<img src={paymentIcon} alt="payments" style={{ maxWidth: '25px' }} />} 
//               sx={{ mt: 2, color: '#fff', fontSize: 16, fontWeight: 'bold', textDecoration: 'underline', color: '#D32F2F',
//               '&:hover': { textDecoration: 'underline' } }} 
//               onClick={()=> navigate("/orders")}>
//         View Orders
//       </Button>
//       </Box>
      
//       {cartItems.length > 0 ? (
//         <TableContainer>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Item</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Price</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Quantity</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Subtotal</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Edit</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold' }}>Remove</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {cartItems.map((item) => (
//                 <TableRow key={item._id}>
//                   <TableCell>
//                     <img src={item.image} alt={item.productName} width="50" height="50" />
//                     <div style={{ fontWeight: 'bold' }}>{item.productName}</div>
//                     <div style={{ color: 'grey' }}>Size: {item.size}</div>
//                     <div style={{ color: 'grey' }}>Color: {item.color}</div>
//                   </TableCell>
//                   <TableCell>PKR{item.unitAmount.toFixed(2)}</TableCell>
//                   <TableCell>{item.quantity}</TableCell>
//                   <TableCell>PKR{(item.unitAmount * item.quantity).toFixed(2)}</TableCell>
//                   <TableCell>
//                     <IconButton onClick={() => handleEdit(item)}>
//                       <EditIcon color="#4A403A" />
//                     </IconButton>
//                   </TableCell>
//                   <TableCell>
//                     <IconButton onClick={() => handleRemove(item._id, item.color)}>
//                       <DeleteIcon color="error" />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       ) : (
//         <Typography style={{ color: 'red', fontWeight: 'bold', marginTop: 10, textAlign: 'center' }}>
//           No cart found.
//         </Typography>
//       )}

//       {cartItems.length > 0 && (
//         <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
//           <Button endIcon={<Redo/>} sx={{ mt: 2, color: '#fff', fontWeight: 'bold', background: '#646EDE', px: 2,
//           '&:hover': { background: '#646EDE' } }} onClick={handleContinueShopping}>
//             Stripe Payment
//           </Button>
//           <Button endIcon={                <img src={easypaisa} alt="payments" style={{ maxWidth: '66px', maxHeight: '95px'  }} />
// } sx={{ mt: 2, color: '#fff', fontWeight: 'bold', background: '#00BE5F', px: 2,
//           '&:hover': { background: '#00BE5F' } }} onClick={handleContinueShopping}>
//              Payment Using
//           </Button>
//         </Box>
//       )}

//       {/* Edit Modal */}
//       <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
//         <Box p={3} bgcolor="white" borderRadius={2} style={{ maxWidth: '400px', margin: 'auto', marginTop: '10%' }}>
//           <h2>Edit Item</h2>
//           <TextField
//             label="Quantity"
//             type="number"
//             value={updatedQuantity}
//             onChange={(e) => setUpdatedQuantity(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <TextField
//             label="Color"
//             value={updatedColor}
//             onChange={(e) => setUpdatedColor(e.target.value)}
//             fullWidth
//             sx={{ mb: 2 }}
//           />
//           <Button variant="contained" onClick={handleUpdate} fullWidth>
//             Update Item
//           </Button>
//         </Box>
//       </Modal>
//     </Box>
//     <Footer/>
//     </React.Fragment>
//   );
// };

// export default CartPage;



import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, List, ListItem, ListItemText, TableHead, TableRow, Button, IconButton, Box, Modal, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Divider, Typography } from 'antd';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { Redo } from '@mui/icons-material';
import paymentIcon from "../../assests/icons/payments.png";
import { useNavigate } from 'react-router-dom';
import easypaisa from "../../assests/icons/easy-paisa.png";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const CartPage = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);

  const [editItem, setEditItem] = useState(null); // Track which item is being edited
  const [editModalOpen, setEditModalOpen] = useState(false); // Control modal visibility


  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(savedCart);
  }, []);
  const handleQuantityUpdate = (id, operation) => {
    const updatedCart = cart.map((item) => {
      if (item.id === id) {
        const newQuantity = operation === "increment" ? item.quantity + 1 : Math.max(item.quantity - 1, 1);
        return { ...item, quantity: newQuantity, totalPrice: newQuantity * item.price };
      }
      return item;
    });
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCart(updatedCart);
  };

  // Open edit modal with selected item data
  const handleEditClick = (item) => {
    setEditItem(item);
    setEditModalOpen(true);
  };


  const handleRemoveFromCart = (id) => {
    const updatedCart = cart.filter((item) => item.id !== id);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCart(updatedCart);
  };

  return (
    <React.Fragment>
    <Navbar/>
    <Box py={4} px={{xs: 1, md:10}} sx={{display: "flex", justifyContent: 'center', alignItems: "center",}}>

    
      
      {cart.length > 0 ? (
        <Box sx={{ width: 500, padding: 2,   }}>
        <Typography  style={{textAlign: 'center', fontWeight:"bold", fontSize:20}}>
          Your Shopping Carts
        </Typography>
        <Divider />
        <List>
        {cart.map((item, index) => (
            <React.Fragment key={item.id}>
<ListItem key={item.id} sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 2 }}>
  
  {/* Product Information Row */}
  <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
    <Box component="img" src={item.image} alt={item.title} sx={{ width: 60, height: 60, marginRight: 2 }} />
    <ListItemText
      primary={item.title}
      
      secondary={
        <>
          <Typography variant="body2">Size: {item.size}</Typography>
          <Typography variant="body2">Color: {item.color}</Typography>
          <Typography variant="body2">Qty: {item.quantity}</Typography>
          <Typography variant="body2">Price: {item.price * item.quantity}</Typography>
        </>
      }
     
    

      sx={{ flex: 1 }}
    />
    <Box  sx={{ flex: 1 }}>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton onClick={() => handleQuantityUpdate(item.id, "decrement")}>
        <RemoveIcon />
      </IconButton>
      <Typography>{item.quantity}</Typography>
      <IconButton onClick={() => handleQuantityUpdate(item.id, "increment")}>
        <AddIcon />
      </IconButton>
    </Box>

    <Box sx={{ display: "flex", alignItems: "center" }}>
      {/* <IconButton onClick={() => handleEditClick(item)}>
        <EditIcon />
      </IconButton> */}
      <IconButton onClick={() => handleRemoveFromCart(item.id)}>
        <DeleteIcon color="error" />
      </IconButton>
    </Box>
    </Box>
    

  </Box>
  <Divider sx={{ borderBottomWidth: 3, backgroundColor: 'red' }} />
   {/* Add a Divider only if it's not the last item */}
 
   
        
            

</ListItem>

</React.Fragment>
))}

        </List>
       
        <Button variant="contained"
        sx={{background: 'black', color: 'white',
          '&:hover': {
            background: 'black'
          }
        }}
        fullWidth onClick={() => navigate("/checkout")}>
          Checkout
        </Button>
      </Box>
       
      ) : (
        <Typography style={{ color: 'red', fontWeight: 'bold', marginTop: 10, textAlign: 'center' }}>
          No cart found.
        </Typography>
      )}

     
    </Box>
    <Footer/>
    </React.Fragment>
  );
};

export default CartPage;
